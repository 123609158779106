<template>
    <!-- NAVBAR -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-transparent">
        <div class="container">
            <!-- <a class="navbar-brand" href="./"><img class="logo img-fluid" src="./assets/logo/logopad.png" alt="logo" /></a> -->
            <a href="" class="logo">
                <h3 class="" data-text="KRISDEWA.">KRISDEWA<span class="logow">.</span></h3>
            </a>
            <div id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link active" href="">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#about">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#skills">Skills & Tools</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#experience">Experience</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#project">Project</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
            <div class="menu-toggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </nav>
    <!-- END NAVBAR -->

</template>

<script>
export default {
    name: 'NavbarComponent',
    mounted() {
        const menuToggle = document.querySelector(".menu-toggle input");
        const nav = document.querySelector(".navbar .navbar-nav");

        menuToggle.addEventListener("click", function () {
            nav.classList.toggle("slide");
        });
    }
}
</script>
<template>
    <!-- CONTACT -->
    <div class="footer" id="contact">
        <div class="container">
            <h2>Contact Me</h2>
            <p>
                For hiring, business, or collaboration please contact me via email
                <strong>krisnadp23@gmail.com</strong> or <strong>LinkedIn</strong>.
            </p>
            <div class="social-media">
                <a href="https://www.instagram.com/krisdewa_/" target="_blank"><img
                        src="frontend/icon/instagram.svg" alt="instagram" /></a>
                <a href="https://www.linkedin.com/in/krisna-dewa-pratama-9aa907153/" target="_blank"><img
                        src="frontend/icon/linkedin.svg" alt="linkedin" /></a>
                <a href="https://github.com/krisdewa" target="_blank"><img src="frontend/icon/github.svg"
                        alt="github" /></a>
                <a href="https://www.youtube.com/c/Krisdewa" target="_blank"><img
                        src="frontend/icon/youtube.svg" alt="youtube" /></a>
            </div>
            <p>
                <strong>From Madiun, Indonesia <br />
                    By KrisDewa &copy;
                    {{ new Date().getFullYear() }}
                </strong>
            </p>

            <!-- <div class="signed-logo">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <img src="./assets/logo/logopad.svg" alt="logopad" />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  </div> -->
        </div>
    </div>
    <!-- ENDCONTACT -->
</template>

<script>
export default {
    name: "ContactComponent",
};
</script>